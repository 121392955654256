import $ from "jquery"

$(document).on(
  "load page:load ready turbo:load up:layer:open up:fragment:inserted",
  function () {
    const associateProfileLink = $("#associate-profile-link")
    const defaultCvLink = $("#default-cv-link")
    const commercialCvLink = $("#commercial-cv-link")
    const contractTypeLink = $("#contract-type-link")

    const userId = $("#user-info").data("user-id")
    const profileId = $("#user-info").data("profile-id")

    const associateProfileHash = `#/profiles/${profileId}/personal-information`
    const defaultCvHash = `#/coaches/${userId}/default-cv/job-information`
    const commercialCvHash = `#/coaches/${userId}/commercial-cv/job-information`
    const contractTypeHash = `#/coaches/${userId}/contract-type`

    function setAtiveLink() {
      associateProfileLink.removeClass("active")
      defaultCvLink.removeClass("active")
      commercialCvLink.removeClass("active")
      contractTypeLink.removeClass("active")
      if (document.location.hash == associateProfileHash) {
        associateProfileLink.addClass("active")
      } else if (document.location.hash == defaultCvHash) {
        defaultCvLink.addClass("active")
      } else if (document.location.hash == commercialCvHash) {
        commercialCvLink.addClass("active")
      } else if (document.location.hash == contractTypeHash) {
        contractTypeLink.addClass("active")
      }
    }

    setAtiveLink()

    associateProfileLink.on("click", (e) => {
      openReactAdminPanel(associateProfileHash, e)
      setAtiveLink()
    })

    defaultCvLink.on("click", (e) => {
      openReactAdminPanel(defaultCvHash, e)
      setAtiveLink()
    })

    commercialCvLink.on("click", (e) => {
      openReactAdminPanel(commercialCvHash, e)
      setAtiveLink()
    })

    contractTypeLink.on("click", (e) => {
      openReactAdminPanel(contractTypeHash, e)
      setAtiveLink()
    })
  }
)

function openReactAdminPanel(hash, e) {
  e.preventDefault()
  if (document.location.pathname.endsWith("/account/edit")) {
    document.location.hash = hash
  } else {
    const locale = $("#user-info").data("locale")
    document.location.href = `/${locale}/account/edit${hash}`
  }
}
