require("flatpickr")
require('select2')();
window.toastr = require("toastr")

function initAdvancedInputFields() {
  if ($(".select2").length) {
    $(".select2").select2({
      dropdownAutoWidth: 'true'
    });
  }
  if($(".flatpickr").length) {
    $(".flatpickr").flatpickr({ static : true });
  }
  if($(".people-analytics-flatpickr").length) {
    $(".people-analytics-flatpickr").flatpickr({
      static : true,
      dateFormat: 'd-m-Y',
      onOpen: function(selectedDates, dateStr, instance) {
        var placeholder = this.element.placeholder
        if (placeholder) {
          instance.setDate(placeholder)
        }
      },
    });
  }
  if (up.element.get(".show-client-name-radio-buttons")) {
    up.observe(".show-client-name-radio-buttons", function(value) {
      params = { 'membership[show_client_name]': value }
      up.render({ target: ".report-texts", url: document.location.href, params: params })
    })
  }
  if (up.element.get(".client-language")) {
    up.observe(".client-language", function(value) {
      params = { 'membership[client_language]': value }
      up.render({ target: ".report-texts", url: document.location.href, params: params })
    })
  }
}

function initAccordion() {
  if($(".accordion").length) {
    $(".accordion").accordion({ active: false, collapsible: true });
  }
}

function autosizeTextArea() {
  $("textarea").each(function () {
    this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
  }).on("input", function () {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
  });
}

function disableScrollOnNumber() {
  // disable mousewheel on a input number field when in focus
  // (to prevent Chromium browsers change the value when scrolling)
  $('form').on('focus', 'input[type=number]', function (e) {
    $(this).on('wheel.disableScroll', function (e) {
      e.preventDefault()
    })
  })
  $('form').on('blur', 'input[type=number]', function (e) {
    $(this).off('wheel.disableScroll')
  })
}

function showHideOptions($select) {
  if (['checkboxes', 'radio_buttons'].indexOf($select.val()) > -1) {
    $select.parents('.nested-fields').find('.question-options').show()
  } else {
    $select.parents('.nested-fields').find('.question-options').hide()
  }
}

function embedCode(id, url) {
  if (url.includes("vimeo")) {
    return `<iframe src="https://player.vimeo.com/video/${id}?autoplay=1" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
  }

  if (url.includes("talks") && url.includes("ted")) {
    return `<iframe src="https://embed.ted.com/talks/${id}" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
  }

  return `<iframe src="https://www.youtube-nocookie.com/embed/${id}" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
}

$(document).on("turbo:load ready", function() {
  initAccordion();
  initAdvancedInputFields();
  disableScrollOnNumber();
  autosizeTextArea();

  $("#clients-accordion .custom-accordion").click(function(e) {
    $(this).parents("tr").next().toggleClass("hidden");
    e.preventDefault();
  });

  $(document).on('change', 'select[id$="input_type"]', function() {
    showHideOptions($(this))
  });
  $('select[id$="input_type"]').each(function() {
    showHideOptions($(this))
  });

  $("[data-dialog]").find(".message .attachment--preview:has(a[href*='youtu.be']),.message .attachment--preview:has(a[href*='youtube.com']), .message .attachment--preview:has(a[href*='vimeo.com']), .message .attachment--preview:has(a[href*='ted.com'])")
    .on("click", function(e) {
      e.preventDefault();
      var attrs = JSON.parse(this.dataset.trixAttachment);
      var id = attrs.externalId;
      var url = attrs.href;
      $(this).find("a").replaceWith(embedCode(id, url));
    });

  $('.copytoclipboard').click(function (e) {
    e.preventDefault();
    navigator.clipboard.writeText($(this).data('url'));
    $(this).addClass('hidden');
    $(this).next().removeClass('hidden');
  });
});

$(document).on('up:layer:open up:fragment:inserted', function() {
  initAccordion();
  initAdvancedInputFields();
  disableScrollOnNumber();
  autosizeTextArea();
});

/**
 * If browser back button was used, flush cache
 * This ensures that user will always see an accurate, up-to-date view based on their state
 * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
 */
(function () {
  window.onpageshow = function(event) {
    if (event.persisted) {
      window.location.reload();
    }
  };
})();
