import { Controller } from "@hotwired/stimulus"

import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  get url() {
    return this.data.get("url")
  }

  click() {
    Turbo.visit(this.url)
  }
}
