import tippy from "tippy.js"
import $ from "jquery"

$(document).on(
  "turbo:load ready up:layer:open up:fragment:inserted",
  function () {
    tippy(".tippy", {
      placement: "right",
      arrow: true,
      animation: "fade",
      theme: "pluform",
      allowHTML: true,
    })
  },
)
