import $ from "jquery"

$(document).on(
  "load page:load ready turbo:load up:layer:open up:fragment:inserted",
  function () {
    $(".lang-switch").on("click", (e) => {
      const locale = $(e.target).attr("id")
      localStorage.setItem("RaStore.locale", locale)
    })
  }
)
