import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["program", "learningsteps", "learningstepsWrapper"]
  programTarget: HTMLInputElement
  learningstepsTarget: HTMLInputElement
  learningstepsWrapperTarget: Element

  connect() {
    this.changeProgram()
  }

  changeProgram() {
    $.ajax({
      url: "/api/learning_steps.json?program_id=" + this.programTarget.value,
      success: (data) => {
        this.refreshDropdownValues(data)
      },
      error: function () {
        console.log("Error retrieving learning steps")
      },
    })
  }

  refreshDropdownValues(data) {
    this.learningstepsTarget.innerHTML = ""

    if (data.length == 0 || data.length == undefined) {
      $(this.learningstepsTarget).removeAttr("required")
      this.learningstepsWrapperTarget.classList.add("hidden")
      return
    }

    for (let i = 0; i < data.length; i++) {
      const learningStep = data[i]
      $(this.learningstepsTarget).attr("required", "true")
      this.learningstepsTarget.innerHTML +=
        '<option value="' +
        learningStep.id +
        '">' +
        learningStep.presentation +
        "</option>"
    }

    this.learningstepsWrapperTarget.classList.remove("hidden")
  }
}
