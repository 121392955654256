import "./boot/sentry.js"

import "@hotwired/turbo-rails"
import "controllers"

import Rails from "@rails/ujs"

import "channels"
import "jquery"
import "unpoly"
import "select2"
import "trix"
import "toastr"

import "./boot/jquery.js"
import "./boot/unpoly.js"
import "./boot/select2.js"
import "./boot/trix.js"
import "./boot/tippy.js"

import "./lib/unpoly-migrate.min.js"
import "./lib/cocoon.js"
import "./lib/mobilemenu.js"
import "./lib/feature-onboarding.js"
import "./lib/associate-profile-menu.js"
import "./lib/locale-switch.js"

import "./lib/init.js"

Rails.start()
