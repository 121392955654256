import * as Trix from "trix/dist/trix"
import $ from "jquery"
import { createColorParser } from "../helpers/color_parser"
import { emoji } from "../helpers/emoji_helper"

var lang = Trix.config.lang

lang.urlPlaceholder = "https://www.example.com"

Trix.config.toolbar = {
  getDefaultHTML() {
    return `<div class="trix-button-row" data-trix-basic-target="editor">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${
          lang.bold
        }" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${
          lang.italic
        }" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${
          lang.link
        }" tabindex="-1">${lang.link}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-fgcolor" data-action="click->editor#toggleColors" title="Change the text colour" tabindex="-1"></button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${
        lang.heading1
      }" tabindex="-1">${lang.heading1}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${
        lang.quote
      }" tabindex="-1">${lang.quote}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${
        lang.bullets
      }" tabindex="-1">${lang.bullets}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${
        lang.numbers
      }" tabindex="-1">${lang.numbers}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-insert" data-trix-button-group="block-insert">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-attachment" data-action="click->editor#addAttachment" title="Insert attachment" tabindex="-1">Insert attachment</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-image" data-action="click->editor#addAttachment" title="Insert image" tabindex="-1">Insert image</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-youtube" data-trix-action="x-embed" title="Embed video" tabindex="-1">Video</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-emoji" data-trix-action="x-insert-emoji" title="Insert emoji" tabindex="-1">Emoji</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${
          lang.undo
        }" tabindex="-1">${lang.undo}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${
          lang.redo
        }" tabindex="-1">${lang.redo}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${
            lang.urlPlaceholder
          }" aria-label="${lang.url}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="btn btn--sm btn--secondary mr-1" value="${
              lang.link
            }" data-trix-method="setAttribute">
            <input type="button" class="btn btn--sm btn--secondary" value="${
              lang.unlink
            }" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
      <div class="trix-dialog trix-dialog--color" data-editor-target="colorsDialog" data-trix-dialog="x-color">
        <table>
          <tr>
            <th class="font-normal text-left pr-2" scope="row">Text colour</th>
            <td>
              <span data-action="click->editor#removeColor" class="swatch swatch--none">None</span>
              <span data-action="click->editor#toggleColorRed" class="swatch swatch--red">Red</span>
              <span data-action="click->editor#toggleColorGreen" class="swatch swatch--green">Green</span>
              <span data-action="click->editor#toggleColorBlue" class="swatch swatch--blue">Blue</span>
              <span data-action="click->editor#toggleColorPurple" class="swatch swatch--purple">Purple</span>
              <span data-action="click->editor#toggleColorYellow" class="swatch swatch--yellow">Yellow</span>
            </td>
          </tr>
          <tr>
            <th class="font-normal text-left pr-2" scope="row">Background colour</th>
            <td>
              <span data-action="click->editor#removeBackgroundColor" class="swatch swatch--none">None</span>
              <span data-action="click->editor#toggleBackgroundRed" class="swatch swatch--red-lighter">Red</span>
              <span data-action="click->editor#toggleBackgroundGreen" class="swatch swatch--green-lighter">Green</span>
              <span data-action="click->editor#toggleBackgroundBlue" class="swatch swatch--blue-lighter">Blue</span>
              <span data-action="click->editor#toggleBackgroundPurple" class="swatch swatch--purple-lighter">Purple</span>
              <span data-action="click->editor#toggleBackgroundYellow" class="swatch swatch--yellow-lighter">Yellow</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="trix-dialog trix-dialog--youtube" data-trix-dialog="x-embed">
        <p class="mb-2">Paste a video URL:</p>
        <div class="mb-2"><input type="text" data-editor-target="embedCode" class="w-full border rounded p-1" placeholder="https://youtu.be/er3aAKt4qzE" /></div>
        <div><input type="button" data-trix-method="hideDialog" data-action="click->editor#insertEmbed" value="Embed media" class="btn btn--secondary btn--sm"></div>
      </div>
      <div class="trix-dialog trix-dialog--emoji" data-editor-target="emojiDialog" data-trix-dialog="x-insert-emoji">
        <div class="emoji-picker">
          <div data-action="click->editor#insertEmoji" class="emoji-picker__list">
            ${emoji()}
          </div>
        </div>
      </div>
    </div>`
  },
}

Trix.config.textAttributes.color = {
  inheritable: true,
  styleProperty: "color",
  parser: createColorParser(),
}

Trix.config.textAttributes.backgroundColor = {
  inheritable: true,
  styleProperty: "backgroundColor",
  parser: createColorParser(),
}

function reloadDialogEditor() {
  $("#html-message-body").remove()
  $("label[for='message_body']").hide()
  $("#js-message-body").show()
  $("#add-toolbox-button").show()
}

$(document).on("turbo:load ready", function () {
  reloadDialogEditor()
})
